import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/bjorn/git/entur-docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { ExpandablePanel } from "@entur/expand";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "recommendations-guide"
    }}>{`Recommendations Guide`}</h1>
    <p>{`This document is a guide for using recommendations in Offers API v2.`}</p>
    <h2 {...{
      "id": "what-is-it-what-does-it-do"
    }}>{`What is it, what does it do?`}</h2>
    <p>{`Recommendations let you specify attributes in an offer that you are interested in. Let's say, you are interested in
SEMI_FLEXIBLE and FLEXIBLE offers in the PREMIUM_CLASS or STANDARD_CLASS.`}</p>
    <p>{`Recommendations will then select offers that fit a combination of those attributes.
Recommendations can be added when searching by
`}<a parentName="p" {...{
        "href": "https://petstore.swagger.io/?url=https://api.entur.io/api-docs/offers#/stop-places/postOffersV2SearchStopPlaces"
      }}>{`stop places`}</a>{`,
`}<a parentName="p" {...{
        "href": "https://petstore.swagger.io/?url=https://api.entur.io/api-docs/offers#/zones/postOffersV2SearchZones"
      }}>{`zones`}</a>{` and
`}<a parentName="p" {...{
        "href": "https://petstore.swagger.io/?url=https://api.entur.io/api-docs/offers#/trip-pattern/postOffersV2SearchTripPattern"
      }}>{`trip patterns`}</a>{`.`}</p>
    <p>{`The possible combinations are:`}</p>
    <p>{`SEMI_FLEXIBLE and PREMIUM_CLASS `}<br />{`
SEMI_FLEXIBLE and STANDARD_CLASS `}<br />{`
FLEXIBLE and PREMIUM_CLASS `}<br />{`
FLEXIBLE and STANDARD_CLASS `}<br /></p>
    <p>{`Now - any offer that satisfies a combination, meaning they possess all the attributes of a given combination,
will be considered for a potential recommendation. If multiple offers "compete" for a combination, the
cheaper is preferred. See the `}<a parentName="p" {...{
        "href": "https://petstore.swagger.io/?url=https://api.entur.io/api-docs/offers#/stop-places/postOffersV2SearchStopPlaces"
      }}>{`api docs`}</a>{` for more details.`}</p>
    <p>{`The combinations are created from the values of each property in the request object.
Additionally, recommendations let you specify certain rules to follow when creating recommendations.`}</p>
    <ExpandablePanel title="Example Request" mdxType="ExpandablePanel">
    <pre>
    {`
         "recommendationConfig": {
             "categorySpec": {
               "typesOfRecommendation": [
                 "FLEXIBLE",
                 "SEMI_FLEXIBLE",
                 "NON_FLEXIBLE",
                 "CHEAPEST",
                 "SPECIFIED_PRODUCT"
               ],
               "durationTypes": [
                   "SINGLE_TRIP",
                   "RETURN_TRIP",
                   "DAY_PASS",
                   "OPEN_ENDED"
               ],
               "fareClasses": [
                 "PREMIUM_CLASS",
                 "STANDARD_CLASS",
                 "ECONOMY_CLASS",
                 "ANY"
               ],
               "facilitySet": [
                 "SLEEPER",
                 "ANY_FACILITY_SET",
                 "COUCHETTE"
               ],
               "specifiedProducts": [
                   "GOA:PreassignedFareProduct:KomfortNonFlexible",
                   "GOA:PreassignedFareProduct:StandardFlexible",
                   "GOA:PreassignedFareProduct:e39282c3"
               ]
             },
             "ruleSpec": {
               "journeyOrganizeAlgorithm": "COMBINATIONS_FROM_OFFERS",
               "priceComparisonAlgorithm": "TOTAL_PRICE",
               "onlyIncludeRecommendedOffers": false,
               "onlyIncludeRecommendationsWithOffersToBuy": true,
               "mixinOffersWithHigherFlexibility": true
             }
           }
        `}
    </pre>
    </ExpandablePanel>
    <ExpandablePanel title="Example Response" mdxType="ExpandablePanel">
    <pre>{`
    {
        "tripPatternId": "6df557eb-46e8-4d12-92e8-bea7bb1e41c5",
        "offers": [
            ...
        ],
        "recommendations": [
            {
                "geographicalValidityCovered": {
                    "serviceJourneys": [
                        "GOA:ServiceJourney:709_337-R"
                    ],
                    "pointToPointValidity": {
                        "fromPlace": "NSR:StopPlace:337",
                        "toPlace": "NSR:StopPlace:596"
                    }
                },
                "typeOfRecommendation": "FLEXIBLE",
                "durationType": "SINGLE_TRIP",
                "fareClass": "PREMIUM_CLASS",
                "facilitySet": "ANY_FACILITY_SET",
                "offersToBuy": [
                    {
                        "id": "7f000e63-a75d-48db-9d51-0341e3c9ebf4",
                        "numberToBuy": 1,
                        "withUpgradeProducts": [],
                        "selectableProductIds": [],
                        "possibleTravellerIds": [
                            [
                                "cfc293de-7ff0-400f-abaf-0e0033bf9691"
                            ]
                        ]
                    }
                ]
            },
            ...
        ],
        "optionalProducts": [
            ...
        ]
    }`}</pre>
    </ExpandablePanel>
    <ExpandablePanel title="Example: Full request body to search/trip-pattern" mdxType="ExpandablePanel">
    <pre>
    {`
   {
     "travellers": [
       {
         "userType": "ADULT",
         "age": 40,
         "luggageTypes": [
           "PUSH_CHAIR",
           "ANIMAL",
           "SMALL_ANIMAL",
           "BICYCLE"
         ]
       }
     ],
     "tripPattern": {
       "legs": [
         {
           "travelDate": "2022-02-20",
           "fromStopPlaceId": "NSR:StopPlace:337",
           "toStopPlaceId": "NSR:StopPlace:596",
           "serviceJourneyId": "GOA:ServiceJourney:709_337-R"
         }
       ]
     },
     "recommendationConfig": {
       "categorySpec": {
         "typesOfRecommendation": [
           "FLEXIBLE",
           "SEMI_FLEXIBLE",
           "NON_FLEXIBLE",
           "CHEAPEST",
           "SPECIFIED_PRODUCT"
         ],
         "durationTypes": [
             "SINGLE_TRIP",
             "RETURN_TRIP",
             "DAY_PASS",
             "OPEN_ENDED"
         ],
         "fareClasses": [
           "PREMIUM_CLASS",
           "STANDARD_CLASS",
           "ECONOMY_CLASS",
           "ANY"
         ],
         "facilitySet": [
           "SLEEPER",
           "ANY_FACILITY_SET",
           "COUCHETTE"
         ],
         "specifiedProducts": [
             "GOA:PreassignedFareProduct:KomfortNonFlexible",
             "GOA:PreassignedFareProduct:StandardFlexible",
             "GOA:PreassignedFareProduct:e39282c3"
         ]
       },
       "ruleSpec": {
         "journeyOrganizeAlgorithm": "COMBINATIONS_FROM_OFFERS",
         "priceComparisonAlgorithm": "TOTAL_PRICE",
         "onlyIncludeRecommendedOffers": false,
         "onlyIncludeRecommendationsWithOffersToBuy": true,
         "mixinOffersWithHigherFlexibility": true
       }
     }
   }
    `}
    </pre>
    </ExpandablePanel>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      